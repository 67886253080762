<template>
  <div class="hiring-container" >
    <BreadcrumbBanner pageName="Projects" />

    <section class="mt-5">
      <div
        class="row my-3 custom-checkboxes justify-content-center"
        v-if="filteredCategories && filteredCategories.length"
      >
        <div class="col-10 col-lg-8">
          <div class="row">
            <div class="swiper-btns col-1 pointer" @click="swiperPrev">
              <font-awesome-icon icon="chevron-left" class="text-pri my-2" />
            </div>
            <Swiper
              class="swiper col-10"
              ref="projSwiper"
              :options="hiringSwiperOptions"
              v-if="dataLoaded"
            >
              <SwiperSlide
                class="col-6 col-lg-4 custom-radio-container mx-3"
                v-for="filter in filteredCategories"
                :key="filter.id"
                v-show="filter.jobs_count != 0"
              >
                <label class="container pe-2">
                  <span class="banner-text">{{ filter.title }}</span>
                  <input
                    type="radio"
                    v-model="chosenFilter"
                    :value="filter.id"
                    @change="loadCat(filter.id)"
                  />
                  <span class="checkmark"></span>
                </label>
              </SwiperSlide>
            </Swiper>
            <div class="swiper-btns col-1 pointer" @click="swiperNext">
              <font-awesome-icon icon="chevron-right" class="text-pri my-2" />
            </div>
          </div>
        </div>
        <!-- <div class="col-12 d-block d-lg-flex justify-content-center">
          
          <div 
            class="custom-radio-container mx-3"
            v-for='cat in categories'
            :key='cat.id'
          >
            <label class="container">
              <span class="banner-text">{{cat.name}}</span>
              <input type="radio" v-model="chosenCategory" :value="cat.id">
              <span class="checkmark"></span>
            </label>
          </div>
        </div> -->
      </div>
      <no-data v-else data-name="Projects"></no-data>
      <div
        class="jobCards-container position-relative"
        :style="{ 'min-height': loading ? '450px' : '' }"
        v-if="getProjects && getProjects.length"
      >
        <div
          class=" m-4 mx-auto"
          v-for="project in getProjects"
          :key="project.id"
        >
          <div class="d-flex justify-content-center pointer ">
              <div
                class="project-card my-2 my-md-3 border-eff p-md-3"
                @click="openModal(project)"
              >
                <div class="project-card-img">
                  <img v-if="project.featured_image_url != null && project.featured_image_url.split('/')[project.featured_image_url.split('/').length-1] != 'null' " :src="project.featured_image_url" />
                  <img v-else-if="project.images && project.images.length > 0" :src="project.images[0] && project.images[0].url" />
                  <img v-else class="holder-project-image" :src="placeHolder" />
                  <div class="category-row">
                    <div class="badge-sec mx-1" v-if="project.featured">
                      featured
                    </div>
                    <div v-if="project.labels" class="d-flex">
                      <div
                        class="badge-pri mx-1"
                        v-for="(label, i) in project.labels"
                        :key="i"
                        :style="{ backgroundColor: label.color }"
                      >
                        {{ label.title }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="project-card-body">
                  <div class="menu-text project-title-text mt-2 mt-md-4">
                    {{ project.titles }}
                    
                  </div>
                  <!-- <div class="project-year mb-4">{{project.year}}</div>
                  <div class="project-category my-4">{{project.category}}</div>
                  <div class="project-description">{{project.description}}</div> -->
                </div>
              </div>
            </div>
        </div>
        <ProjectLoader v-if="loading" />
      </div>
      <no-data v-if="getProjects.length == 0 && !loading" data-name="Projects"></no-data>
    </section>
    <section class="mt-5">
      <NewsletterForm />
      <AppFooter :changeForceScroll="changeForceScroll" />
    </section>
    <router-view :viewdJob="viewdJob"></router-view>
  </div>
</template>

<script>
import ProjectLoader from "@/components/misc/ProjectLoader.vue";
import BreadcrumbBanner from "@/components/misc/BreadcrumbBanner.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import AppFooter from "@/components/Navigation/AppFooter.vue";
import NewsletterForm from "@/components/forms/NewsletterForm.vue";
import { mapActions, mapGetters } from "vuex";
import NoData from "@/components/misc/NoData.vue";
export default {
  components: {
    BreadcrumbBanner,
    Swiper,
    SwiperSlide,
    NewsletterForm,
    AppFooter,
    ProjectLoader,
    NoData,
  },
  data() {
    return {
      placeHolder: require('../assets/images/logos/Group 17369@2x.png'),
      text: "",
      dataLoaded: false,
      loading: false,
      chosenFilter: 0,
      viewdJob: {},
      hiringSwiperOptions: {
        speed: 1000,
        grabCursor: true,
        // centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 0,
        // loop: true,
      },
      filters: [],
      jobs: [],
      prjects: null,
    };
  },
  props: ["changeForceScroll", "projectsTitle", "openModal", "closeModal", "myModalShow"],
  methods: {
    ...mapActions(["loadProjects", "loadCategories", "loadVProject"]),
    swiperNext() {
      this.projSwiper.slideNext(500);
    },
    swiperPrev() {
      this.projSwiper.slidePrev(500);
    },
    viewProject(id) {
      this.loadVProject({ id: id }).then((res) => {
        if (res.status == 200) {
          this.$router.push({ name: "ViweJob", params: { id: id } });
          this.viewdJob = this.getVJob;
        }
      });
    },
    loadCat(id) {
      (this.loading = true),
        this.loadProjects({ id: id })
          .then((res) => {
            if (res.status == 200) {
              // this.projects = this.getProjects;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
    },
  },
  mounted() {
    this.chosenFilter = this.$route.params.id;
    console.log(this.chosenFilter)
    this.loadCat(this.chosenFilter);
    this.loadCategories()
      .then((res) => {
        if (res.status == 200) {
          // this.filters = this.getCategories;
          // for (const key in this.filters) {
            //   if (Object.hasOwnProperty.call(this.filters[0], key)) {
              //     console.log(key);
          //     const element = this.filters[0][key];
          //     console.log(element);
          //   }
          // }
          this.dataLoaded = true;
          let interwait = setInterval(() => {
            if(this.projSwiper) {
              let catInd = this.filteredCategories.findIndex(el => el.id == this.chosenFilter)
              this.projSwiper.slideTo(catInd)
              window.clearInterval(interwait)
            }
          }, 50)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    ...mapGetters(["getProjects", "getCategories", "getVProject"]),
    filteredCategories() {
      return this.getCategories.filter(el => el.parent_id == 11);
    },
    projSwiper() {
      return this.$refs.projSwiper.$swiper;
    },
  },
};
</script>