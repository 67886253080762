<template>
  <div class="hiring-container">
    <!-- <BreadcrumbBanner pageName="We Are Hiring" /> -->
    <section class="" v-if="filters.length">
      <div class="my-5 search-form-container">
        <form @submit.prevent="submit">
          <div class="row justify-content-center">
            <div class="col-xl-8 col-11">
              <div class="relative-sec">
                <input
                  required
                  type="text"
                  placeholder="Job title or keyword"
                  class="text-input-lg"
                  v-model="text"
                />
                <button
                  class="btn-pri btn-inform slide-header shadow-eff"
                  type="submit"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>

    <section>
      <div
        class="row my-3 custom-checkboxes justify-content-center"
        v-if="filters.length"
      >
        <div class="col-10 col-lg-8">
          <div class="row">
            <div class="swiper-btns col-1 pointer" @click="swiperPrev">
              <font-awesome-icon icon="chevron-left" class="text-pri my-2" />
            </div>
            <Swiper
              class="swiper col-10"
              ref="hiringSwiper"
              :options="hiringSwiperOptions"
              v-if="dataLoaded"
            >
              <SwiperSlide class="col-6 col-lg-3 custom-radio-container mx-3">
                <label class="container pe-2">
                  <span class="banner-text">All</span>
                  <input
                    type="radio"
                    v-model="chosenFilter"
                    :value="0"
                    @change="loadCat(undefined)"
                  />
                  <span class="checkmark"></span>
                </label>
              </SwiperSlide>
              <SwiperSlide
                class="col-6 col-lg-4 custom-radio-container mx-3"
                v-for="filter in filters"
                :key="filter.id"
                v-show="filter.jobs_count != 0"
              >
                <label class="container pe-2">
                  <span class="banner-text">{{ filter.title }}</span>
                  <input
                    type="radio"
                    v-model="chosenFilter"
                    :value="filter.id"
                    @change="loadCat(filter.id)"
                  />
                  <span class="checkmark"></span>
                </label>
              </SwiperSlide>
            </Swiper>
            <div class="swiper-btns col-1 pointer" @click="swiperNext">
              <font-awesome-icon icon="chevron-right" class="text-pri my-2" />
            </div>
          </div>
        </div>
        <!-- <div class="col-12 d-block d-lg-flex justify-content-center">
          
          <div 
            class="custom-radio-container mx-3"
            v-for='cat in categories'
            :key='cat.id'
          >
            <label class="container">
              <span class="banner-text">{{cat.name}}</span>
              <input type="radio" v-model="chosenCategory" :value="cat.id">
              <span class="checkmark"></span>
            </label>
          </div>
        </div> -->
      </div>
      <no-data v-else data-name="Jobs"></no-data>
      <div
        class="jobCards-container position-relative"
        :style="{ 'min-height': loading ? '450px' : '' }"
        v-if="filters.length"
      >
        <div
          class="job-card-container m-4 mx-auto"
          v-for="job in jobs"
          :key="job.id"
        >
          <div class="job-card-content">
            <h4 class="job-card-header my-4">
              {{ job.title }}
            </h4>
            <div class="d-flex justify-content-between my-4">
              <h6 class="job-card-location text-muted">
                <font-awesome-icon icon="map-marker-alt" class="" />
                {{ job.locations }}
              </h6>
              <div
                class="my-auto"
                :class="
                  job.type == 'Full-Time' ? 'badge-pri-r1' : 'badge-sec-r1'
                "
              >
                {{ job.type }}
              </div>
            </div>
          </div>
          <div class="job-card-footer mt-8 d-flex justify-content-between">
            <div class="">
              <button
                type="button"
                class="btn btn-text"
                @click="viweJob(job)"
              >
                View Details
              </button>
            </div>
            <h6 class="job-card-location text-muted">
              Ceated at:
              {{
                job.created_at.match(
                  /(\d{4}([.\-/ ])\d{2}\2\d{2}|\d{2}([.\-/ ])\d{2}\3\d{4})/
                )[0]
              }}
            </h6>
          </div>
        </div>
        <ProjectLoader v-if="loading" />
      </div>
      <no-data v-if="jobs.length == 0 && !loading" data-name="Jobs"></no-data>
    </section>
    <section class="mt-5">
      <NewsletterForm />
      <AppFooter :changeForceScroll="changeForceScroll" />
    </section>
    <router-view :viewdJob="viewdJob" :listJob='listJob'></router-view>
  </div>
</template>

<script>
import ProjectLoader from "@/components/misc/ProjectLoader.vue";
import BreadcrumbBanner from "@/components/misc/BreadcrumbBanner.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import AppFooter from "@/components/Navigation/AppFooter.vue";
import NewsletterForm from "@/components/forms/NewsletterForm.vue";
import { mapActions, mapGetters } from "vuex";
import NoData from "@/components/misc/NoData.vue";
export default {
  components: {
    BreadcrumbBanner,
    Swiper,
    SwiperSlide,
    NewsletterForm,
    AppFooter,
    ProjectLoader,
    NoData,
  },
  data() {
    return {
      text: "",
      dataLoaded: false,
      loading: false,
      chosenFilter: 0,
      viewdJob: {},
      hiringSwiperOptions: {
        speed: 1000,
        grabCursor: true,
        // centeredSlides: true,
        slidesPerView: "auto",
        spaceBetween: 0,
        // loop: true,
      },
      filters: [],
      jobs: [],
    };
  },
  props: ["changeForceScroll"],
  methods: {
    ...mapActions(["loadJobs", "loadJobCategories", "loadVJob"]),
    swiperNext() {
      this.hiringSwiper.slideNext(500);
    },
    swiperPrev() {
      this.hiringSwiper.slidePrev(500);
    },
    viweJob(job) {
      this.loadVJob({ id: job.id }).then((res) => {
        if (res.status == 200) {
          this.$router.push({ name: "ViweJob", params: { id: job.id } });
          this.viewdJob = this.getVJob;
          this.listJob = job;
        }
      });
    },
    loadCat(id) {
      (this.loading = true),
        this.loadJobs({ job_category: id })
          .then((res) => {
            if (res.status == 200) {
              this.jobs = this.getJobs;
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
    },
  },
  mounted() {
    this.loadCat();
    this.loadJobCategories()
      .then((res) => {
        if (res.status == 200) {
          this.filters = this.getJobCategories;
          // for (const key in this.filters) {
          //   if (Object.hasOwnProperty.call(this.filters[0], key)) {
          //     console.log(key);
          //     const element = this.filters[0][key];
          //     console.log(element);
          //   }
          // }
          this.dataLoaded = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    ...mapGetters(["getJobs", "getJobCategories", "getVJob"]),
    hiringSwiper() {
      return this.$refs.hiringSwiper.$swiper;
    },
  },
};
</script>