<template>
  <div class="hiring-container">
    <!-- <BreadcrumbBanner pageName="Contact Us" /> -->
    <h4 class="contact-us-title text-center my-5 mt-8">
      Feel Free To Drop Us A Message
    </h4>
    <section class="row justify-content-center my-5">
      <div class="col-12 col-lg-10">
        <div class="row flex-lg-row-reverse">
          <div class="col-12 col-lg-3 contact-us-form-img my-3">
            <img
              src="./../assets/images/pexels.jpg"
              width="100%"
              height="100%"
            />
          </div>
          <div class="col-12 col-lg-9 px-4">
            <form @submit.prevent="submit">
              <div
                class="row my-3"
                v-for="field in contctFields"
                :key="field.name"
              >
                <div class="col-12">
                  <input
                    v-if="field.type != 'textarea'"
                    class="text-input"
                    :type="field.type"
                    :id="field.name"
                    pattern=".{4,}"
                    title="4 characters minimum"
                    :placeholder="field.placeholder"
                    v-model="formData[field.name]"
                    @input='vlaidateText(field)'
                  />
                  <textarea
                    v-else
                    class="text-input-area"
                    rows="8"
                    :id="field.name"
                    :placeholder="field.placeholder"
                    v-model="formData[field.name]"
                    @input='vlaidateText(field)'  
                  />

                  <small v-show='errors[field.name]' class="text-danger p-1"> 
                    <font-awesome-icon :icon="['fas', 'times']" />
                    {{errors[field.name]}}
                  </small>
                </div>
              </div>
              <h4 v-if="messageSent" v-html="messageSent"></h4>
              <div class="my-4 submit-btn px-4">
                <button
                  class="btn-pri-sm position-relative"
                  :style="{ 'min-height': loading ? '45px' : '' }"
                  type="submit"
                >
                  <span v-if="!loading"> Submit </span>
                  <ProjectLoader v-else />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <div class="divider-100 text-center"></div>

    <section class="my-5">
      <ContactPanels />
      <div id="map" style="width: 100%; height: 600px"></div>
    </section>

    <section>
      <NewsletterForm />
      <AppFooter :changeForceScroll="changeForceScroll" />
    </section>
  </div>
</template>
<script>
import ProjectLoader from "@/components/misc/ProjectLoader.vue";
import BreadcrumbBanner from "@/components/misc/BreadcrumbBanner.vue";
import ContactPanels from "@/components/ContactPanels.vue";
import AppFooter from "@/components/Navigation/AppFooter.vue";
import NewsletterForm from "@/components/forms/NewsletterForm.vue";
import { Loader } from "@googlemaps/js-api-loader";
import { mapActions } from "vuex";
export default {
  components: {
    BreadcrumbBanner,
    ContactPanels,
    AppFooter,
    NewsletterForm,
    ProjectLoader,
  },

  data() {
    return {
      loading: false,
      contctFields: [
        {
          label: "name",
          name: "name",
          type: 'text',
          placeholder: "Name",
          type: "text",
          required: true,
        },
        {
          label: "email",
          name: "email",
          type: "email",
          placeholder: "Email Address",
          type: "email",
          required: true,
        },
        {
          label: "subject",
          name: "subject",
          type: 'text',
          placeholder: "Subject",
          type: "text",
          required: true,
        },
        {
          label: "message",
          name: "message",
          placeholder: "Message",
          type: "textarea",
          required: true,
        },
      ],
      formData: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      errors: {},
      isFormValide: false,
      defaultForm: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      messageSent: "",
    };
  },
  props: ["changeForceScroll"],
  methods: {
    ...mapActions(["sendMessage"]),
    vlaidateText(field) {
      let el = document.getElementById(field.name);
      let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if(field.name == 'email') {
        if(this.formData[field.name] && this.formData[field.name].toLowerCase().match(regex)) {
          this.errors[field.name] = '';
          el.style.borderColor = 'black';
          this.$forceUpdate()
          this.isFormValide = true
          return true;
        }else {
          this.errors[field.name] = 'Email Address is not valid';
          el.style.borderColor = 'red';
          this.$forceUpdate()
          this.isFormValide = false
          return false;
        }
      }else {
        if(this.formData[field.name] && this.formData[field.name].length > 4) {
          this.errors[field.name] = '';
          el.style.borderColor = 'black';
          this.$forceUpdate()
          this.isFormValide = true
          return true
        }else {
          this.errors[field.name] = 'Text has to be longer than 4 characters';
          el.style.borderColor = 'red';
          this.$forceUpdate()
          this.isFormValide = false
          return false
        }
      }
    },
    validateSubmit() {
      this.contctFields.forEach(el => {
        if(el.required == true && this.formData[el.name] == '') {
          let element = document.getElementById(el.name);
          this.errors[el.name] = 'This field is required';
          element.style.borderColor = 'red';
          this.isFormValide = false;
          this.$forceUpdate()
        }
      })
    },
    submit() {
      this.validateSubmit();

      if(this.isFormValide == false) {
        return false;
      }
      console.log(this.isFormValide)
      this.loading = true;
      this.sendMessage(this.formData)
        .then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.messageSent = "Thank you, we will contact you soon";
            this.formData = this.defaultForm;
          } else {
            this.loading = false;
            this.messageSent = `We're sorry, something bad happened. Error code ${res.status}`;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.messageSent = `We're sorry, something bad happened.<br/> ${err}`;
        });
    },
  },
  watch: {
    messageSent(nMsg) {
      if (nMsg) {
        // setTimeout(() => {
          // this.messageSent = "";
        // }, 4000);
      }
    },
  },
  created() {
    // Create Map
    // const position = { lat: 29.995187991018618, lng: 31.127957964694666 };
    // new google.maps.Map(document.getElementById("map"), {
    //   zoom: 20,
    //   center: position,
    // });
    // new google.maps.Marker({
    //   position: uluru,
    //   map: map,
    // });

    // const loader = new Loader({
    //   apiKey: "AIzaSyCz_1dZ9BhzGiTIsxjPaBl4ZB30SOMvBxM",
    // });
    // Selected Place LatLng
    // Map Intialization
    // loader.load().then(() => {
      // const map = new google.maps.Map(document.getElementById("map"), {
      //   center: position,
      //   zoom: 16,
      //   mapId: "8e0a97af9386fef",
      //   zoomControl: true,
      //   mapTypeControl: true,
      //   mapTypeId: "roadmap",
      //   mapTypeControlOptions: {
      //     style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
      //     mapTypeIds: ["terrain"],
      //   },
      // });
      // Map Marker Configuration
      // const svgMarker = {
      //   path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
      //   fillColor: "#78909C",
      //   fillOpacity: 1,
      //   strokeWeight: 1,
      //   rotation: 1,
      //   scale: 3,
      //   backgroundColor: "#fff",
      //   anchor: new google.maps.Point(11.5, 21),
      // };
      // const marker = new google.maps.Marker({
      //   position,
      //   map,
      //   icon: require("../assets/images/map_marker.svg"),
      //   draggable: true,
      //   animation: google.maps.Animation.DROP,
      // });
      // function toggleBounce() {
      //   if (marker.getAnimation() !== null) {
      //     marker.setAnimation(null);
      //   } else {
      //     marker.setAnimation(google.maps.Animation.BOUNCE);
      //   }
      // }
      // const img = require("../assets/images/logos/Group 16297.png");
      // const detailWindow = new google.maps.InfoWindow({
      //   content: `<img style='padding: 10px 5px 10px 20px' src='${img}' alt='logo' />`,
      // });
      // marker.addListener("load", toggleBounce);
      // marker.addListener("click", () => {
      //   map.setZoom(16);
      // });
      // marker.addListener("mouseover", () => {
      //   detailWindow.open(map, marker);
      // });
    // });
  },
  mounted() {
   const loader = new Loader({
      apiKey: "AIzaSyDwjAGhdaZz-krtd8bYTFCbsczvCtIO03I",
    });
    const position = { lat: 29.995187991018618, lng: 31.127957964694666 };
    // Selected Place LatLng
    // Map Intialization
    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("map"), {
        center: position,
        zoom: 16,
        mapId: "12a8a4acf250f911",
        zoomControl: true,
        mapTypeControl: false,
        mapTypeId: "terrain",
        disableDefaultUI: true,
        // mapTypeControlOptions: {
        //   style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        //   mapTypeIds: ["terrain", "roadmap"],
        // },
      });
      const marker = new google.maps.Marker({
        position,
        map,
        // icon: require("../assets/images/map_marker.svg"),
        animation: google.maps.Animation.DROP,
      });
      const img = require("../assets/images/logos/Group 16297.png");
      const detailWindow = new google.maps.InfoWindow({
        content: `<img style='padding: 10px 5px 10px 20px' src='${img}' alt='logo' />`,
      });
      marker.addListener("load", toggleBounce);
      marker.addListener("click", () => {
        map.setZoom(16);
      });
      function toggleBounce() {
        if (marker.getAnimation() !== null) {
          marker.setAnimation(null);
        } else {
          marker.setAnimation(google.maps.Animation.BOUNCE);
        }
      }
      marker.addListener("mouseover", () => {
        detailWindow.open(map, marker);
      });
    })
  }
};
</script>